.modal{
    pointer-events: none;
}
.modal-dialog:not(.confirm){
    width: 80vw;
    height: 90vh;
    max-height: 90vh;
    pointer-events: none;
    display: flex;
    flex-direction: column;
}


.modal-content,
.modal-history-content{
    height: 100%;
    flex-direction: column;
    display: flex;
    flex: 1 1 auto;
    overflow: hidden;
    border-radius: 8px;
    pointer-events: auto;


    .modal-header{
        padding: 1.714rem;

        .modal-title{
            font-size: 1.25em;
        }
    }
    
    .modal-body{
        flex: 1;
        overflow-y: auto;
        padding: 1.714rem;
    }

    @import "modal_hist";

    &.hide{
        >.modal-header,
        >.modal-body,
        >.modal-footer{
            display: none;
        }
    }
}

.modal-footer{
    padding: 1.714rem;
    justify-content: space-between !important;
    .btn-history{
        margin: 0;
        padding: 0;

        .fa-history{
            color: $primary-orange-light;
            margin-right: 8px;
        }
    }
    >* {
        margin: 0 !important;
    }

    div:nth-child(2){
        display: flex;
        gap: 1.143rem;
    }
}


//notice
div.errorNotices:has(p){
    border-radius: 5px;
    padding: 1rem;
    color: #842029;
    background-color: #f8d7da;
    border-color: #f5c2c7;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    margin-bottom: 1rem;

    p{
        font-size: 0.9rem;
        margin: 0;
        span{
            font-weight: 600;
        }
    }
}
