
.accordion-header{
    border-bottom: 1px solid $grey-100;
    margin-bottom: 1.143rem;
    .accordion-button{
        border: none;
        background: unset;
        margin: 0;
        padding: 0;
        width: 100%;
        text-align: left;
        padding: 1rem 0; 
        font-size: 1.3rem;
        font-weight: 600;

        &:focus{
            border: unset;
            outline: unset;
        }
    }

    .icon {
        float: right;
        transition: transform 0.2s ease; /* Sanfte Rotation */
    }

    /* Drehe das Icon, wenn das benachbarte .accordion-body die Klasse .show hat */
    &:has(+ .accordion-collapse.show) .icon {
        transform: rotate(180deg);
    }
}

.accordion-collapse{
    padding: 0;
}