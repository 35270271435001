.btn-outline {
  background-color: #fff !important;
  border-color: $primary-orange !important;
  color: $grey-500 !important;

  &:hover,
  &:focus,
  &.disabled,
  &:disabled {
    color: #fff !important;
    background-color: $primary-orange !important;
    border-color: $primary-orange !important;
  }
}

.btn-primary {
  display: inline-flex;
  padding: 0.5rem 1rem;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.25rem;
  border-color: unset;
  background: $primary-orange;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.06px;

  &:hover,
  &:focus,
  &.disabled,
  &:disabled {
    border-color: unset;
    background-color: $primary-orange-light;
  }
}

.btn-secondary {
  display: inline-flex;
  padding: 0.5rem 1rem;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.25rem;
  border-color: $grey-100;
  background: #fff;
  color: $primary-orange;
  font-size: 1rem;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.06px;

  &:hover,
  &:focus,
  &.disabled,
  &:disabled,
  &:not(:disabled):not(.disabled):active {
    border-color: $primary-orange-light;
    background-color: #fff;
    color: $grey-200;
  }

  &.tableActionButton {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    padding: 8px;
    gap: 8px;
  }
}

.btn-csv {
  @extend .btn-primary; // Erbt alle Stile von `.btn-primary`

  background: #fff; 
  color: $grey-500;
  border-color: $grey-100;
  &:hover,  &:focus {
    color: $grey-200;
    background-color: #ffffff9a; // Beispiel: Ändere die Hover-Farbe
  }
}

//btn-nav
.btn-nav {
  display: flex;
  width: 13.25rem;
  min-height: 2.25rem;
  padding: var(--Radius-radii-m, 0.5rem) 1.25rem var(--Radius-radii-m, 0.5rem)
    1rem;
  align-items: center;
  gap: 0.75rem;
}

.btn-sm {
  font-size: 0.857rem;
}


.btn-link{
  color: $grey-500;
  font-weight: 500;
  font-size: 1em;

  &:hover{
    color: $primary-orange-light;
  }
}
