div.filterToolBox{

    #filter{
        border-end-end-radius: 0;
        border-top-right-radius: 0;
        border: 1px solid $grey-100;
        border-right: 0;
    }
    input[name="filterText"]{
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: 0;
    }

    //höhe anpassen für mehr abstand
    .custom-row{
        height: 44px; /* Feste Höhe für das übergeordnete Container-Element */
        .input-group, 
        .form-control {
            display: flex;
            align-items: stretch;
            height: 100%;
        }
        .input-group span{
            align-items: center;
        }
    }

    span.filterBox{
        display: flex;
        padding: 2px 12px 2px 12px;
        border: 1px solid $primary-orange;
        border-radius: 100px;
        margin-top: 1rem;
        font-size: 12px;
        line-height: 20px;
        color: $grey-500;
        align-items: center;
        cursor: default;


        i.icon{
            font-size: 12px !important;
            margin: 0 !important;
            padding-left: 8px;
            color: $primary-orange;

            &:hover{
                cursor: pointer;
            }
        }
    }
}