//loading fonts
@import './fonts/load_fonts';


//MIXINs
@mixin font($weight: 400, $color: $grey-400){
    font-family: Inter;
    font-style: normal;
    color: $color;
    font-weight: $weight;
}
@mixin text-styles($font-size, $line-height, $letter-spacing) {
    font-size: $font-size !important;
    line-height: $line-height;
    letter-spacing: $letter-spacing;
}

//ALLGEMEIN
html, 
body{
    @include font(400);
    font-size: 14px;
}

//HEADING
h1{
    @include font(600, #242322);
    @include text-styles(24px, 32px, -0.24px);
}

.heading-xl {
    @include font(600);
    @include text-styles(1.5rem, 2rem, -0.015rem);
}
 
.heading-md {
    @include font(600);
    @include text-styles(1rem, 1.25rem, 0.002rem);
}

//TEXT
.txt-body-small{
    @include font(400, #232422);
    @include text-styles(0.857rem, 1rem, 0.06px);
}
//LABEL
@mixin label-styles($size:0.857rem, $line-height:20px, $letter-spacing:0.05px, $weight: null, $color: null) {
    @include font($weight, $color);
    @include text-styles($size, $line-height, $letter-spacing);
}

.txt-label-semibold {
    @include label-styles($size: 0.857rem, $letter-spacing: 0.06px, $weight: 600, $color: #000);
}

.txt-label-small-regular {
    @include label-styles;
}

.txt-label-small-semibold {
    @include label-styles($weight: 600);
}

//CAPS
@mixin caps-styles($size: 0.857rem, $line-height: 20px, $letter-spacing: 0.05px, $weight: 500) {
    @include font($weight);
    @include text-styles($size, $line-height, $letter-spacing);
    text-transform: uppercase;
}

.txt-caps-small-regular{
    @include caps-styles;
}
.txt-caps-small-semibold{
    @include caps-styles($weight: 600);
}