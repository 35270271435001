/**
* Allgemeine Sass Datei
*/
/*Inhalt
VARIABLEN
    sass
 FONTS
    css-variablen

BUTTONs
SIDEBAR
CONTENT
UTILS
LOGIN
MODAL
FORMs
TABELLEN
#REPORT

*/

// VARIABLEN

//SASS Variablen
//Colors
//grey
$grey-50: #f8f7f7;
$grey-100: #e6e5e4;
$grey-200: #9e9494;
$grey-300: #574d4d;
$grey-400: #342e2e;
$grey-500: #141111;

//Primary Colors
$primary-orange: #f60;
$primary-orange-light: #ff811a;

//Action Colors
$action-green: #a3e635;
$action-green-light: #d3fea7;
$action-red: #dc2626;
$action-red-light: #fcdede;
$action-yellow: #fbbf24;
$action-yellow-light: #ffeeda;
$orange_drop_down: #ffd370;
$orange_drop_down_hover: #ffeebe;

//SHADOWS
/* box-shadow-01 */
$box-shadow-01: 0px 2px 6px 0px rgba(48, 49, 51, 0.1),
  0px 0px 1px 0px rgba(48, 49, 51, 0.05);

//ICONS
@import "./utils/icons";
//FONTS
@import "./fonts";

//Styles aus public/index.html
@import "./index_inline_styles";

//CSS-variablen
.hide {
  display: none;
}

.w-auto {
  width: auto !important;
}
.w100 {
  width: 100%;
}

.rotated {
  transform: rotate(180deg);
  transition: transform 0.3s ease-in-out; /* Optional: Animation der Drehung */
}

//random Components
@import "selectField";

//Button
@import "./button";

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
    center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}

//header
nav.main-header {
  height: 90px; //um mit Brand-logo auf einer höhe zu sein
  border-bottom: 1px solid #f1f0f0;

  @import "./header";
}
@media (min-width: 992px) {
  .sidebar-mini.sidebar-collapse .content-wrapper,
  .sidebar-mini.sidebar-collapse .main-footer,
  .sidebar-mini.sidebar-collapse .main-header {
    margin-left: 5.6rem !important;
  }
}

// SIDEBAR
@import "./sidebar.scss";
@import "./sidebar-collapse";

//CONTENT
div.content-wrapper {
  background-color: $grey-50;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;

  @import "my_layout";

  .content{
    display: flex;
    .container-fluid{
      flex-direction: column;
      display: flex;
      flex: 1;
      section.formBox{
        display: flex;
        .card{
          flex: 1;
        }
      }
    }
  }
}

// UTILS
@import "utils/spinner";

// TOOLs
/// FILTER BOX
@import "filterBox";

// MODAL
@import "modal";

//Card FOrms
section.formBox{

  .card-body,
  .card-footer{
    padding: 1.714rem;
  }
}



// LOGIN
.login-box {
  .login-logo {
    img {
      width: auto;
      height: 70px;
    }
  }

  .login-card-body {
    padding: 1.5rem;
    padding-bottom: 0;
  }

  span.fas {
    //icon color
    color: $primary-orange;
  }
  .callout.callout-warning {
    border-left-color: $action-red;
    color: $action-red;
    text-align: center;
    h6 {
      margin: 0 !important;
    }
  }
}

// MODAL
.modal {
  .modal-header {
    background-color: $grey-50;
  }

  .alert {
    min-height: 50px;
    margin: 10px;
    background-color: $primary-orange-light;
  }
}

// FORMs
@import "./form";

.formWrapper form {
  input[type="file"].form-control {
    border: none !important;
    padding-left: 0;
  }
  .basic-select {
    color: $grey-500 !important;
  }
}

#dateien {
  a {
    color: $primary-orange;
    &:hover {
      color: $primary-orange-light;
    }
  }
  p {
    margin: 0;
    color: $grey-500 !important;
  }
  .datei-name {
    margin-top: 0.5rem;
  }
  i.far.fa-file-alt {
    font-size: 40px;
  }
  i.fas.fa-times {
    position: absolute;
    top: 5px;
    right: 10px;
    color: red;
    &:hover {
      cursor: pointer;
    }
    display: none;
  }
  .datei:hover {
    background-color: lightgrey;
    i.fas.fa-times {
      display: block;
    }
  }
}

.form-control:focus,
.form-control:focus ~ .input-group-append .input-group-text {
  border-color: $orange_drop_down !important;
}

//TABELLEN
@import "table";

// pagination
div.pagination-wrapper {
  .btn-group {
    button.btn {
      color: $primary-orange !important;
      background-color: unset !important;
      border: 1px solid $primary-orange !important;
    }
    .dropdown-menu {
      .dropdown-item {
        color: $primary-orange;
        &:hover {
          color: $primary-orange-light;
        }
      }
    }
  }

  ul.pagination {
    .page-link {
      color: $primary-orange;
      width: 35px;

      &:disabled {
        background-color: #e9ecef;
      }
      &.page:disabled {
        color: white;
        background-color: $primary-orange;
      }
    }
  }
}
// #REPORT
tr.ampel {
  &.red {
    background-color: #ff7559;
    &:hover {
      background-color: #ff755980 !important;
    }
    // background-color: #ff5100;
  }
  &.yellow {
    background-color: #ffe959;
    // background-color: #ffdd00;
    &:hover {
      background-color: #ffe95980 !important;
    }
  }
  &.green {
    background-color: #bbf255;
    // background-color: #95cc00;
    &:hover {
      background-color: #bbf25580 !important;
    }
  }
}

//# Mitarbeiter Übersicht Statistik buttons
.stat-wrapper {
  // width: 150px;
  .btn {
    position: relative;
    padding: 5px 0;
    margin-left: -5px;

    &[aria-hidden="true"] {
      display: none;
    }
    &.stat-red {
      // background-color:#ff7559;
      background-color: #e83c3e;
      z-index: 5;
    }
    &.stat-yellow {
      // background-color: #ffe959;
      background-color: #d3cd0e;
      z-index: 10;
    }
    &.stat-green {
      // background-color: #bbf255;
      background-color: #53af32;
      z-index: 15;
    }
    &:hover {
      // z-index: 20;
      border: 1px solid black;
    }
  }
}

//Dashboard
.widget-wrapper {
  @import "./widgetCard";
  flex: 1;

  .widget-piechart {
    .recharts-wrapper {
      width: 100% !important;
    }
    svg > g.recharts-pie:first-of-type {
      filter: drop-shadow(0px 4px 8px rgba(48, 49, 51, 0.1))
        drop-shadow(0px 0px 1px rgba(48, 49, 51, 0.05));
    }

    .pie-legend {
      margin-right: 38px;

      > div {
        margin-bottom: 10px;
        padding: 5px 10px;
        &:hover {
          background-color: #f8f7f7;
          cursor: pointer;
        }
      }

      label.txt-caps-small-regular {
        color: #b3aca9;
        font-weight: 500 !important;
        margin: 0;
      }
      p {
        margin: 0;
        font-weight: 700;
      }

      .legend-color {
        width: 13px;
        height: 13px;
        border-radius: 50%;
        display: inline-block;
        margin-right: 7px;

        &.green {
          background-color: $action-green;
        }
        &.yellow {
          background-color: $action-yellow;
        }
        &.red {
          background-color: $action-red;
        }
      }
    }
  }

  .widget-overview {
    div.recharts-wrapper svg {
      .recharts-yAxis,
      .recharts-xAxis {
        .recharts-cartesian-axis-line {
          display: none;
        }
        .recharts-cartesian-axis-ticks {
          .recharts-cartesian-axis-tick-line {
            display: none;
          }
          text {
            color: #b3aca9;
            font-size: 0.857rem;
            text-transform: uppercase;
          }
        }
      }
    }
  }

  .mail-widget {
    a {
      color: $primary-orange;
    }
    .bg-info {
      background-color: $primary-orange !important;
    }
    .info-box:hover {
      opacity: 0.75;
      background-color: #e9ecef;
    }
  }
}

//dashboard einstellunge
.alert-box {
  position: fixed;
  bottom: 20px;
  right: 20px;
  .alert-success {
    padding: 25px;
  }
}
#info-message {
  .alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1.25rem 1rem;
  }
  .btn-close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: 0.25em 0.25em;
    color: #000;
    background: transparent
      url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
      center/1em auto no-repeat;
    border: 0;
    border-radius: 0.25rem;
    opacity: 0.5;
  }
}
//EINSTELLUNGEN
#options {
  @import "einstellungen/einstellungen";

  @import "einstellungen/auto-email";
}


//OVERRIDES
@import "accordion";
