//Offen
aside.main-sidebar{
    border-right: 1px solid #F1F0F0;
    background-color: #fff;
    z-index: 1030;

    .os-content{
        display: flex;
    }

    //LOGO
    .brand-link{
        height: 90px;
        width: 5.6rem !important;
        background-color: #ffffff !important;
        text-align: center;
        .brand-image{
            height: auto;
            margin: 10px 16px;
            box-shadow: none;
        }
    }

    .sidebar{
        height: calc(100vh - (90px + 1px)) !important;
        //Nav
        nav{
            display: flex;
            flex-direction: column;
            flex: 1;

            .nav-sidebar{
                overflow: visible;
            }
            span.nav-title{
                color: #B3ACA9;
                padding: 8px 0.5rem;
            }

            li.nav-item{
                // height: 36px;
                border-radius: 4px;
                &:hover,
                &.active{
                    background-color: $grey-50;
                }

                >.nav-link {
                    margin: 0;
                    padding: 1.1rem 1rem;
                    display: flex;
                    align-items: center;
                    justify-content: left;
                    >span{
                        // display: flex !important;
                        i.sii{
                            background-color: $primary-orange;
                        }
                        p{
                            margin-left: 12px;
                            font-size: 0.857rem;
                            color: #242322;
                            font-weight: 500;
                            text-transform: uppercase;
                        }
                    }
                }
                .nav-link:hover,
                &.active .nav-link{
                    i.sii{
                        background-color: #B3ACA9;
                    }
                    p{
                        font-weight: 600;
                    }
                }
            }

            ul#erweitert-nav{
                // margin-top: auto;
                flex: 1;
                justify-content: end;
            }
        }
    }

}