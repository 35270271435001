
.widget-card{
    // border-radius: 8px;
    // border: 1px solid #F1F0F0;
    
    .card{
        background: #FFF;

        .card-header{
            border-bottom: 1px solid#F1F0F0;
            display: flex;
            
            .title{
                color: #242322;
                font-weight: 600;
                letter-spacing: 0.032px;
            }

            .card-header-actions{
                margin-left: auto;
                font-size: 0.857rem;
                font-weight: 500;
                letter-spacing: 0.05px;
                display: flex;
                gap: 16px;
                
                span{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;
                    border-radius: 4px;
                    padding: 4px 16px;

                    &:hover{
                        cursor: pointer;
                        background-color: $grey-50;
                    }
                }
                .csv{
                    i.sii{
                        background-color: #B3ACA9;
                    }
                }
                .uebersicht{
                    a{
                        color: inherit;
                        font-size: 0.857rem;
                        font-weight: 500;
                        letter-spacing: 0.05px;
                        display: flex;
                        display: inherit;
                        gap: 8px;
                    }
                    i.sii{
                        background-color: $primary-orange;
                    }
                }
            }
        }

        .widget-toolbox{
            margin: 0px 24px;
            span{
                color: #676361;
            }
        }
    }
}