
//Heading
.control{
    .btn{
        color: #f47a37;
        font-size: 1.05rem;
        padding: 0;
        width: 28px;
        height: 28px;
    }
}

//accordion 
#accordionEmails {
    .card {
        .card-header{
            button.btn-link{
                color: black;
                font-weight: 600;
                width: 100%;
                text-align: left;
            }
        }
    }

    .mail-content{
        margin-top: -30px;
    }

    div.form-group.email{
        form.rjsf{
            select#root_to_Begrenzung_taetigkeit,
            select#root_to_Begrenzung_untersuchungen{
                height: 250px;
            }
        }
    }
}

//Einzelne Email Einstellungen
.form-group.email{
    // border: 1px solid grey;
    // padding: 10px;
    .field-description,
    .desc{
        font-size: 0.75em;
        font-style: italic;
        margin-top: -0.75rem;
    }
    .mail-context{
        margin-top: -2rem;
    }
    p.field-description{
        margin-bottom: 0;
    }
    .options{
        border: 1px solid rgba(0,0,0,.125);
        border-radius: 0.25rem;
        padding: 10px;
        margin-bottom: 1rem;
    }
}

fieldset legend {
    font-size: inherit;
    font-weight: 700;
}

.form-group.si-oneOf{
    .form-group.si-oneOf{ //das zweite brauch einen rahmen
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 0.25rem;
        padding: 10px;

        label.control-label[for="root_sofort"],
        #root_when__title{
            display: none;
        }
    }
}

.mail-settings{
    #MailSettings {
        .field-boolean{
            .control-label{
                display: none;
            }
            input, 
            .field-description{
                margin-right: 15px;
            }
        }
    }

    
    .card{
        height: 350px;
        transition: height 0.4s linear;
        overflow-y: hidden;

        .closeArea{
            position: absolute;
            margin-left: 50%;
            margin-right: 50%;
            top: 5px;
            left: 0;
            transition: transform 0.4s ease-in;

            &:hover{
                cursor: pointer;
            }
        }
        
        .card-body .wrapperMailSettings{
            visibility: visible;
            opacity: 1;
            overflow: hidden;
            transition: visibility 0.4s, opacity 0.4s linear;
        }
        
        &.collapsed{
            height: 36px;
            .card-body .wrapperMailSettings{
                visibility: hidden;
                opacity: 0;
                overflow: hidden;
            }

            .closeArea{
                transform: rotate(3.142rad);
            }
        }
    }
    

}

