.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: $primary-orange !important;
}

.nav-pills .nav-link:not(.active):hover {
    color: $primary-orange-light !important;
}

.widget-config .form-group{
    display: flex;
    justify-content: start;
    align-items: center;

    label[for]{
        margin-right: 15px;
        font-weight: 500;
        font-size: 1rem;
    }
}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 28px;

    /* Hide default HTML checkbox */
    input {
        display: none;

        &:checked+.slider {
            background-color: $primary-orange;
        }

        &:focus+.slider {
            box-shadow: 0 0 1px $primary-orange;
        }

        &:checked+.slider:before {
            -webkit-transform: translateX(20px);
            -ms-transform: translateX(20px);
            transform: translateX(20px);
        }
    }

    /* The slider */
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;

        &:before {
            position: absolute;
            content: "";
            height: 20px;
            width: 20px;
            left: 4px;
            bottom: 4px;
            background-color: white;
            -webkit-transition: .4s;
            transition: .4s;
        }

        /* Rounded sliders */
        &.round {
            border-radius: 34px;
        }
        
        &.round:before {
            border-radius: 50%;
        }
    }
}